@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@500&display=swap");
.animated-text {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

::-webkit-scrollbar {
  background-color: #7a5af844;
  width: 5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #7a5af8;
  border-radius: 5px;
}
